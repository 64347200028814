import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pricing from 'components/Checkout/pricing.jsx';
import Purchase from 'components/Checkout/purchase.jsx';
import Confirmation from 'components/Checkout/confirmation';

const styles = theme => ({
  paper: {
    margin: 'auto',
    padding: theme.spacing(3)
  }
});

class Checkout extends Component {
  state = {
    selectedPlan: null,
    orderComplete: false
  }

  selectPlan = (plan) => {
    this.setState({
      selectedPlan: plan
    });
  }

  render() {
    const { orderComplete, selectedPlan } = this.state;
    return (
      <Grid container direction="row" alignItems="center" justify="center">
        {!orderComplete ? (
          <Grid item md={6} xs={12}>
            {selectedPlan === null ? (
              <Pricing handleSelect={this.selectPlan} />
            ) : (
              <Purchase
                onCheckout={() => {
                  this.setState({
                    orderComplete: true
                  })
                }}
                plan={selectedPlan}
              />
            )}
          </Grid>
        ) : (
          <Grid item md={6} xs={12}>
            <Confirmation />
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(Checkout);
