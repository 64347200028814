import React, { Component } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import client from 'client';

const cardOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const styles = theme => ({
  checkoutContainer: {
    margintop: theme.spacing(10),
  },
  pricingCellHeader: {
    padding: theme.spacing(4),
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: theme.palette.primary[500],
  },
  paper: {
    margin: 'auto',
    padding: theme.spacing(3)
  },
  pricingContainer: {
    marginTop: theme.spacing(10)
  },
  paperGrid: {
    minHeight: 400
  },
  purchasePaper: {
    padding: theme.spacing(3),
    minHeight: 400,
  },
  pricingCellBody: {
    paddingTop: theme.spacing(1),
    minHeight: 258,
  },
  pricingCellPrice: {
    marginTop: theme.spacing(3),
    minHeight: 60
  },
  pricingDollarSign: {
    fontSize: theme.spacing(3),
  },
  pricingPerMonth: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minHeight: 32,
  },
  pricingSelectButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  contactUs: {
    fontSize: '2.75rem'
  },
  selectionsContainer: {
    marginTop: theme.spacing(3),
  }
});

class CheckoutForm extends React.Component {
  state = {
    clientSecret: null,
    processing: false,
    cardholder_name: '',
    cardholder_email: '',
    error: '',
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value
    });
  }

  orderComplete = () => {
    const { onCheckout } = this.props;
    onCheckout();
  }

  handleSubmit = async (event) => {
    const { cardholder_email } = this.state;
    event.preventDefault();
    this.setState({
      error: '',
      processing: true,
    }, async () => {
      const { stripe, elements } = this.props;
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        this.setState({
          error: error
        })
      } else {
        this.createCustomerAndSubscription(paymentMethod.id, cardholder_email)
      }
    });
  };

  createCustomerAndSubscription = (paymentMethod, cardholder_email) => {
    const { plan, stripe } = this.props;
    client.createCustomer({ email: cardholder_email, payment_method: paymentMethod, product: plan }).then((res) => {
      const { subscription } = res;
      const { latest_invoice } = subscription;
      const { payment_intent } = latest_invoice;

      if (payment_intent) {
        const { client_secret, status } = payment_intent;

        if (status === 'requires_action') {
          stripe.confirmCardPayment(client_secret).then((stripeResponse) => {
            if (stripeResponse.error) {
              this.setState({
                error: stripeResponse.error
              })
            } else {
              this.confirmSubscription(subscription.id)
            }
          })
        } else {
          this.orderComplete();
        }
      } else {
        this.orderComplete();
      }
    }).catch((error) => {
      this.setState({
        error: error
      });
    });
  }

  confirmSubscription = (subscriptionId) => {
    client.confirmSubscription(subscriptionId).then((res) => {
      this.orderComplete();
    }).catch((err) => {
      this.setState({ error: err.message });
    })
  }

  render() {
    const { stripe } = this.props;
    const { error, processing, cardholder_name, cardholder_email } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Checkout
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="name"
              placeholder="Cardholder Name"
              fullWidth
              required
              onChange={this.handleChange('cardholder_name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="email"
              variant="outlined"
              name="cardholder_email"
              placeholder="Cardholder Email"
              required
              fullWidth
              onChange={this.handleChange('cardholder_email')}
            />
          </Grid>
          <Grid item xs={12}>
            <CardElement
              className="sr-input"
              options={cardOptions}
            />
          </Grid>
          <Grid item xs={12}>
            {error.length > 0 &&
              <Typography variant="subtitle2" align="center">{error}</Typography>
            }
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              margin="normal"
              variant="contained"
              color="primary"
              disabled={
                processing ||
                !stripe ||
                cardholder_name.length === 0 ||
                cardholder_email.length === 0
              }
              size="large"
              fullWidth
            >
              {processing ? "Processing…" : "Pay"}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm plan={props.plan} onCheckout={props.onCheckout} stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

class Purchase extends Component {
  render() {
    const { classes, plan, onCheckout } = this.props;

    return (
      <Grid container direction="row" justify="center" alignItems="center" className={classes.pricingContainer} spacing={2}>
        <Grid container direction="row" alignItems="center" justify="center" spacing={4} className={classes.checkoutContainer}>
          <Grid item md={4}>
            <Paper className={classes.paperGrid}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
              >
                <Grid item xs={12} className={classes.pricingCellHeader}>
                  <Typography align="center" variant="h3">{plan.name}</Typography>
                  <Typography align="center" variant="subtitle1">{plan.num_tags}</Typography>
                </Grid>
                <Grid spacing={2} item xs={12} className={classes.pricingCellBody}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" align="center">{plan.tag_line}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.pricingCellPrice}>
                    <Typography variant="h2" align="center"><sup className={classes.pricingDollarSign}>$</sup>{plan.price}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.pricingPerMonth}>
                    <Typography variant="h6" align="center">Per Month</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={8}>
            <Paper className={classes.purchasePaper}>
              <Elements stripe={stripePromise}>
                <InjectedCheckoutForm onCheckout={onCheckout} plan={plan} />
              </Elements>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Purchase);
