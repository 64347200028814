import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

class Header extends React.Component {
  state = {
    anchorEl: null,
    isMenuOpen: false,
  }

  toggleMenu = (e) => {
    const { isMenuOpen } = this.state;
    this.setState({
      anchorEl: e.currentTarget,
      isMenuOpen: !isMenuOpen
    });
  }

  goHome = () => {
    const { history } = this.props;
    history.push('/');
  }

  logout = () => {
    localStorage.removeItem('Authorization-Token');
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  goToAccount = () => {
    const { history } = this.props;
    this.setState({
      isMenuOpen: false
    }, () => {
      history.push('/account');
    })
  }

  render() {
    const { classes, children, loggedIn } = this.props;
    const { isMenuOpen, anchorEl } = this.state;
    return (
      <React.Fragment>
        <AppBar id="appHeader" color="primary" position="sticky" >
          <Toolbar>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <IconButton
                  onClick={this.goHome}
                  color="inherit"
                  size="small"
                >
                  <img alt="logo" src="/images/logo-white.png" style={{ width: 48, height: 48 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs />
              <Grid item />
              {loggedIn &&
                <Grid item>
                  <IconButton onClick={this.toggleMenu} color="inherit" className={classes.iconButtonAvatar}>
                    <AccountCircleIcon fontSize="large" />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={this.toggleMenu}
                  >
                    <MenuItem onClick={this.goToAccount}>My Account</MenuItem>
                    <MenuItem onClick={this.logout}>Logout</MenuItem>
                  </Menu>
                </Grid>
              }
            </Grid>
          </Toolbar>
        </AppBar>
        {children}
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
