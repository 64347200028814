import React, { Component } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const styles = theme => ({
  paper: {
    margin: 'auto',
    padding: theme.spacing(3)
  },
  pricingContainer: {
    marginTop: theme.spacing(10)
  },
  paperGrid: {
    minHeight: 400
  },
  pricingCellHeader: {
    padding: theme.spacing(4),
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: theme.palette.primary[500],
  },
  pricingCellBody: {
    paddingTop: theme.spacing(1),
    minHeight: 258,
  },
  pricingCellPrice: {
    marginTop: theme.spacing(3),
    minHeight: 60
  },
  pricingDollarSign: {
    fontSize: theme.spacing(3),
  },
  pricingPerMonth: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minHeight: 32,
  },
  pricingSelectButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  contactUs: {
    fontSize: '2.75rem'
  },
  selectionsContainer: {
    marginTop: theme.spacing(3),
  }
});

const selections = [
  { name: 'Family', num_tags: 'Up to 3 Tags', tag_line: 'Perfect for individuals and families', price: '0.99', plan: 'family' },
  { name: 'Business', num_tags: 'Up to 20 Tags', tag_line: 'Perfect for small businesses', price: '5.00', plan: 'business' },
]

class Pricing extends Component {
  selectPlan = (plan) => {
    const { handleSelect } = this.props;
    handleSelect(plan)
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center" className={classes.pricingContainer} spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography align="center" variant="h3">Pricing Plans</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography align="center" variant="h6">Pricing plans for Families, Small Businesses and Fleets</Typography>
        </Grid>
        <Grid container direction="row" alignItems="center" justify="center" spacing={4} className={classes.selectionsContainer}>
          {selections.map((selection) => {
            return (
              <Grid item xs={4}>
                <Paper className={classes.paperGrid}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                  >
                    <Grid item xs={12} className={classes.pricingCellHeader}>
                      <Typography align="center" variant="h3">{selection.name}</Typography>
                      <Typography align="center" variant="subtitle1">{selection.num_tags}</Typography>
                    </Grid>
                    <Grid spacing={2} item xs={12} className={classes.pricingCellBody}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" align="center">{selection.tag_line}</Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.pricingCellPrice}>
                        <Typography variant="h2" align="center"><sup className={classes.pricingDollarSign}>$</sup>{selection.price}</Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.pricingPerMonth}>
                        <Typography variant="h6" align="center">Per Month</Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.pricingSelectButton}>
                        <Button color="secondary" onClick={() => {
                          this.selectPlan(selection)
                        }} fullWidth size="large" variant="contained">Select</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
          <Grid item md={4} xs={12}>
            <Paper className={classes.paperGrid}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
              >
                <Grid item xs={12} className={classes.pricingCellHeader}>
                  <Typography align="center" variant="h3">Enterprise</Typography>
                  <Typography align="center" variant="subtitle1">1000+ Tags</Typography>
                </Grid>
                <Grid spacing={2} item xs={12} className={classes.pricingCellBody}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" align="center">Perfect for Large Fleets</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.pricingCellPrice}>
                    <Typography className={classes.contactUs} variant="h2" align="center">Contact Us</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.pricingPerMonth} />
                  <Grid item xs={12} className={classes.pricingSelectButton}>
                    <Button color="secondary" fullWidth size="large" variant="contained">Contact Us</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Pricing);
