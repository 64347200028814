import React, { Component } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  marginTop: {
    marginTop: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  bottomMarginLine: {
    borderBottom: `1px solid #efefef`,
  }
})

class NoMatch extends Component {
  goBack = () => {
    const { history } = this.props;
    history.back();
  }

  goHome = () => {
    const { history } = this.props;
    history.push('/');
  }
  render() {
    const { classes } = this.props;

    return (
      <Grid container direction="row" justify="center" alignItems="center" className={classes.marginTop}>
        <Grid item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" variant="h4">
                  Page Not Found
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.bottomMarginLine}>
                <Typography align="center" variant="subtitle1">
                  The Page You've Requested cannot be found.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={4}>
                    <Button onClick={this.goBack} color="primary" fullWidth>Go Back</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={this.goHome} color="primary" fullWidth>Go To Homepage</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(NoMatch);
