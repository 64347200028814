import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tags from 'components/QrCodes/index';
import { loadStripe } from '@stripe/stripe-js';
import Onboarding from 'components/Onboarding/index';
import client from 'client';
import queryString from 'query-string';
import swal from '@sweetalert/with-react';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  main: {
    flex: 1,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    background: '#eaeff1',
  },
  paperStat: {
    margin: 'auto',
    minHeight: '20vh',
  },
  amountVisited: {
    backgroundColor: '#ff00007a'
  },
  amountWaitlisted: {
    backgroundColor: '#ffe3007a'
  },
  amountAdmitted: {
    backgroundColor: '#0010ff7a'
  },
  amountEnrolled: {
    backgroundColor: '#00ff3469'
  }
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class Dashboard extends React.Component {
  state = {
    isLoading: true,
    qr_codes: [],
    finishedOnboarding: false,
    placedOrderCancelled: false,
    placedOrderSuccess: false,
  };

  componentDidMount() {
    const { alertUser, history } = this.props;
    if (window.location.pathname === "/orders/cancelled") {
      window.analytics.page('/orders/cancelled');
      swal({
        title: "Oops",
        text: "There was a problem placing your order.\n Please try again. ",
        icon: "error",
        button: "Try Again"
      }).then(() => {
        history.push('/');
      });
    } else if (window.location.pathname === "/orders/success") {
      window.analytics.page('/orders/success');
      const prms = queryString.parse(this.props.location.search);
      if (prms.session_id) {
        client.reconcileCheckoutSession(prms.session_id).then((res) => {
          global.currentUser = res.user;
          swal({
            title: "Thank You!",
            text: "Thanks for your placing your order.\n You will receive a confirmation email shortly.",
            icon: "success"
          }).then(() => {
            history.push('/');
          })
        }).catch((err) => {
          alertUser(err.message, "error");
        });
      }
    }
    client.fetchUserCodes(global.currentUser).then((res) => {
      window.analytics.page('/dashboard');
      this.setState({
        isLoading: false,
        qr_codes: res.qr_codes
      });
    }).catch((err) => {
      alertUser(err.message, "error");
    });
  }

  fetchCodes = () => {
    const { alertUser } = this.props;
    client.fetchUserCodes(global.currentUser).then((res) => {
      this.setState({
        qr_codes: res.qr_codes
      });
    }).catch((err) => {
      alertUser(err.message, "error");
    });
  }

  finishedOnboarding = async () => {
    const { alertUser } = this.props;
    this.setState({
      isLoading: true,
    }, async () => {
      const res = await client.createCheckoutSession();
      const checkoutSessionId = res.id;
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSessionId
      });
      alertUser(error.message, "error");
    });
  }

  render() {
    const { classes, history, alertUser } = this.props;
    const { qr_codes, isLoading, finishedOnboarding } = this.state;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} container direction="row" spacing={1}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <React.Fragment>
                  {qr_codes.length > 0 ? (
                    <Tags alertUser={alertUser} tags={qr_codes} history={history} newUser={finishedOnboarding} fetchCars={this.fetchCodes} />
                  ) : (
                    <Onboarding alertUser={alertUser} finishedOnboarding={this.finishedOnboarding} />
                  )}
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
