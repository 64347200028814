import axios from 'axios';
import qs from 'qs';

const headers = {
  'Content-Type': 'application/json',
};

const authToken = localStorage.getItem('Authorization-Token');
if (authToken) {
  console.log('called');
  headers['Authorization-Token'] = authToken;
}

const v1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  headers,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
});

let client = {
  createUser: (user) => v1.post('/signup', { user: user }).then(res => res.data),
  verifyPhoneNumber: (user, code) => v1.post(`/users/${user.id}/verify`, { verification_code: code }).then(res => res.data),
  fetchCurrentUser: () => v1.get('/sessions/me', {}).then(res => res.data),
  fetchUserCodes: (user) => v1.get(`/users/${user.id}/qr_codes`).then(res => res.data),
  createUserCode: (user, data) => v1.post(`/users/${user.id}/qr_codes`, { qr_code: data }).then(res => res.data),
  updatePhoneNumber: (user, data) => v1.post(`/users/${user.id}/update_phone_number`, data).then(res => res.data),
  verifyUpdatedPhoneNumber: (user, data) => v1.post(`/users/${user.id}/verify_updated_phone_number`, data).then(res => res.data),
  createPaymentIntent: (plan) => v1.post(`/create-payment-intent`, { product: plan, payment_method_types: ['card'] }).then(res => res.data),
  createCustomer: (data) => v1.post('/create-customer', data).then(res => res.data),
  authenticate: (data) => v1.post('/sessions', data).then(res => res.data),
  fetchTagBySecret: (secret) => v1.get(`/qr_code?qr_secret=${secret}`).then(res => res.data),
  contactCustomer: (code, arg) => v1.post(`qr_codes/${code.id}/messages`, { message_type: arg }).then(res => res.data),
  createCheckoutSession: () => v1.post(`/checkouts`).then(res => res.data),
  reconcileCheckoutSession: (checkoutSessionId) => v1.post(`/checkouts/${checkoutSessionId}/reconcile`, {}).then(res => res.data),
}

export default client;
