import React, { Component } from 'react';
import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { withStyles } from '@material-ui/core/styles';
import client from 'client';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  textField: {
    marginTop: 20
  }
})

class PhoneNumberUpdate extends Component {
  state = {
    step: 1,
    phone_number: '',
    verification_code: '',
    errors: []
  }

  componentDidMount() {
    this.setState({
      phone_number: global.currentUser.phone_number
    });
  }

  handleChangePhoneNumber = (val) => {
    this.setState({
      phone_number: val,
    });
  }

  handleChangeVerificationCode = (e) => {
    this.setState({
      verification_code: e.target.value
    });
  }

  changePhoneNumber = (e) => {
    e.preventDefault();
    const { phone_number } = this.state;
    client.updatePhoneNumber(global.currentUser, { phone_number: phone_number }).then((res) => {
      this.setState({
        step: 2
      });
    }).catch((err) => {
      alert(err.message);
    });
  }

  verifyUpdatedPhoneNumber = (e) => {
    const { alertUser } = this.props;
    e.preventDefault();
    const { verification_code, phone_number } = this.state;
    client.verifyUpdatedPhoneNumber(global.currentUser, { phone_number: phone_number, verification_code: verification_code }).then((res) => {
      this.setState({
        step: 1
      }, () => {
        alertUser('Phone Number Updated Successfully', 'success');
      });
    }).catch((err) => {

    });
  }

  render() {
    const { classes } = this.props;
    const { step, phone_number, verification_code } = this.state;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          {step === 1 ? (
            <React.Fragment>
              <form onSubmit={this.changePhoneNumber} className={classes.form}>
                <MuiPhoneNumber
                  defaultCountry={'us'}
                  onlyCountries={['us']}
                  onChange={this.handleChangePhoneNumber}
                  margin="normal"
                  label="Phone Number"
                  name="Phone Number"
                  variant="outlined"
                  required
                  fullWidth
                  className={classes.textField}
                  value={phone_number}
                />
                <FormControl margin="normal" fullWidth>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={phone_number.trim().length <= 8}
                    fullWidth
                  >
                    Update Phone Number
                  </Button>
                </FormControl>
              </form>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant="subtitle2">We just sent you a verification code. Please enter it below.</Typography>
              <form onSubmit={this.verifyUpdatedPhoneNumber} className={classes.form}>
                <TextField
                  size="large"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  name="verification_code"
                  onChange={this.handleChangeVerificationCode}
                  inputProps={{ minLength: 5 }}
                  label="Verification Code"
                  autoFocus
                  className={classes.textField}
                />
                <FormControl margin="normal" fullWidth>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={!(verification_code.length === 5)}
                    fullWidth
                    className={classes.submit}
                  >
                    Verify
                  </Button>
                </FormControl>
              </form>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(PhoneNumberUpdate);
