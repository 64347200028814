import React, { Component } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TagShow from 'components/QrCodes/show';
import client from 'client';

const styles = theme => ({});

class Tag extends Component {
  state = {
    tag: null,
    isLoading: true
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { secret } = params;
    client.fetchTagBySecret(secret).then((res) => {
      this.setState({
        tag: res,
        isLoading: false,
      });
    }).catch((err) => {
      alert(err.message);
    });
  }
  render() {
    const { isLoading, tag } = this.state;
    return (
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TagShow actionable={true} tag={tag} showBuy={false} />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Tag);
