import React, { Component } from 'react';
import {
  Grid,
  Typography,
  Paper,
  TextField,
  MenuItem,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import client from 'client';

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  comma: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.33,
    letterSpacing: '0em',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  smallMarginTop: {
    marginTop: theme.spacing(2),
  }
});

const years = [
  '2020', '2019', '2018', '2017', '2016', '2015',
  '2014', '2013', '2012', '2011', '2010', '2009',
  '2008', '2007', '2006', '2005', '2004', '2003',
  '2002', '2001', '2000', '1999', '1998', '1997',
  '1996', '1995', '1994', '1993', '1992', '1991',
  '1990', '1989', '1988', '1987', '1986', '1985',
  '1984', '1983', '1982', '1981', '1980', '1979',
  '1978', '1977', '1976', '1975', '1974', '1973',
  '1972', '1971', '1970', '1969', '1968', '1967',
  '1966', '1965', '1964', '1963', '1962', '1961',
  '1960', '1959', '1958', '1957', '1956', '1955',
  '1954', '1953', '1952', '1951', '1950'
];

class Onboarding extends Component {
  state = {
    year: '',
    make: '',
    model: '',
    license_plate_number: '',
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  }

  buttonDisabled = () => {
    const { year, make, model } = this.state;

    return !(year.length >= 4 && make.length > 1 && model.length > 1);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { finishedOnboarding } = this.props;
    const {
      year,
      make,
      model
    } = this.state;
    const data = {
      year,
      make,
      model
    }

    client.createUserCode(global.currentUser, data).then((res) => {
      finishedOnboarding();

    }).catch((err) => {
      alert(err.message);
    })
  }

  render() {
    const { classes } = this.props;
    const { year, make, model, license_plate_number } = this.state;
    return (
      <React.Fragment>
        <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
          <Grid item xs={12} className={classes.smallMarginTop}>
            <Typography align="center" variant="h4">Let's Add Your First Car</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} alignItems="center" justify="center">
          <Grid item md={5} xs={12}>
            <Paper className={classes.paper}>
              <form onSubmit={this.handleSubmit}>
                <Grid container direction="row" spacing={2} justify="center" alignItems="flex-end" >
                  <Grid item xs={12}><Typography align="left" variant="h5">Car Details </Typography></Grid>
                  <Grid item xs={12}><TextField required variant="outlined" fullWidth select label="year" placeholder="2020" name="year" value={year} onChange={this.handleChange('year')}>{years.map(year => (<MenuItem value={year}>{year}</MenuItem>))}</TextField></Grid>
                  <Grid item xs={12}><TextField required variant="outlined" fullWidth label="Make" placeholder="Subaru" name="make" value={make} onChange={this.handleChange('make')} /></Grid>
                  <Grid item xs={12}><TextField required fullWidth variant="outlined" label="Model" placeholder="Outback" name="model" value={model} onChange={this.handleChange('model')} /></Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth variant="outlined" label="License Plate Number" placeholder="444XXYY" name="license_plate_number" value={license_plate_number} onChange={this.handleChange('license_plate_number')} />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} alignItems="center" justify="center" className={classes.marginTop}>
                  <Grid item xs={12} md={4}>
                    <Button fullWidth disabled={this.buttonDisabled()} type="submit" color="secondary" variant="contained">Add Car</Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Onboarding);
