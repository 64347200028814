import React, { Component } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ShowTag from 'components/QrCodes/show';
import AddNewDialog from 'components/QrCodes/add';
import Joyride from 'react-joyride';

const styles = theme => ({
  fullHeight: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    paddingLeft: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  canvas: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  },
  padded: {
    padding: theme.spacing(1),
  },
  tagButton: {
    height: theme.spacing(8),
    fontSize: '1.338rem',
  },
  fab: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  helpOutlineButton: {
    position: 'absolute',
    bottom: theme.spacing(2),
    margin: theme.spacing(1),
    left: theme.spacing(2)
  },
  paper: {
    paddingBottom: theme.spacing(2)
  }
});

class Tags extends Component {
  state = {
    selected: null,
    showOnboarding: false,
    showAddNewDialog: false,
    steps: [
      {
        target: '#tagShow',
        content: "This is your carwala tag.\n You can place this tag on your windshield so people can notify you if something happens to your car.",
        placement: 'right-start'
      },
    ]
  }

  componentDidMount() {
    const { tags, newUser } = this.props;
    if (tags.length > 0) {
      this.setState({
        selected: tags[0],
        showOnboarding: newUser,
      });
    }
  }

  handleClickAdd = () => {
    this.setState({
      showAddNewDialog: true
    });
  }

  closeAddNewDialog = () => {
    const { fetchCars } = this.props;
    this.setState({
      showAddNewDialog: false
    }, fetchCars);
  }

  triggerOnboarding = () => {
    this.setState({
      showOnboarding: true
    })
  }

  selectTag = (tag) => {
    this.setState({
      selected: tag
    })
  }

  render() {
    const { tags, classes, alertUser } = this.props;
    const { selected, steps, showOnboarding, showAddNewDialog } = this.state;
    return (
      <React.Fragment>
        <Joyride
          steps={steps}
          showProgress={false}
          showSkipButton={false}
          run={showOnboarding}
          floaterProps={{
            autoOpen: true,
          }}
          locale={{
            back: 'Back',
            close: 'Close',
            next: 'Next',
            skip: 'Skip',
            last: 'Last'
          }}
        />
        <Grid container direction="row">
            <Grid item xs={12} sm={12} lg={8} container direction="row" justify="center" alignItems="center">
              <ShowTag alertUser={alertUser} actionable={false} tag={selected} showBuy={true} />
            </Grid>
            <Grid item sm={12} xs={12} lg={4} className={classes.fullHeight}>
              <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
                <Grid item xs={12} className={classes.marginTop}>
                  <Typography id="carsTableHeader" variant="h5">Your Cars</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <Table id="carsTable" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" >Make</TableCell>
                          <TableCell align="center">Model</TableCell>
                          <TableCell align="center">Year</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody id="firstRow">
                        {tags.map((tag, idx) => {
                          return (
                            <TableRow style={{ cursor: 'pointer' }} hover key={tag.id} onClick={() => { this.selectTag(tag) }} selected={selected && selected.id === tag.id}>
                              <TableCell align="center">{tag.make}</TableCell>
                              <TableCell align="center">{tag.model}</TableCell>
                              <TableCell align="center">{tag.year}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <Grid item xs={9} />
                  <Grid item xs={4}>
                    <Button align="right" onClick={this.handleClickAdd} color="secondary">Add New Car</Button>
                  </Grid>
              </Grid>
          </Grid>
          <AddNewDialog open={showAddNewDialog} handleClose={this.closeAddNewDialog} />
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Tags);
