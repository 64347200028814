import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { StripeProvider } from 'react-stripe-elements';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import clsx from 'clsx';
import blue from '@material-ui/core/colors/blue';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import Routes from 'routes';
import client from 'client';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      {...other}
    />
  );
}

let theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  mixins: {
    toolbar: {
      maxHeight: 60,
    },
  },
});

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

class App extends React.Component {
  state = {
    isLoading: true,
    mobileOpen: false,
    loggedIn: false,
    showError: false,
    alertMessage: '',
    alertLevel: '',
  };

  componentDidMount() {
    client.fetchCurrentUser().then((res) => {
      this.setGlobalCurrentUser(res.user);
      window.analytics.identify(res.user.id);
      this.setState({
        loggedIn: true,
        isLoading: false,
      });
    }).catch((err) => {
      this.setGlobalCurrentUser(null);
      this.setState({
        loggedIn: false,
        isLoading: false,
        center: null
      });
    })
  }

  alertUser = (message, level) => {
    this.setState({
      showError: true,
      alertMessage: message,
      alertLevel: level,
    });
  }

  closeAlert = () => {
    this.setState({
      showError: false
    });
  }

  handleDrawerToggle = () => {
    const { mobileOpen } = this.state;
    this.setState({
      mobileOpen: !mobileOpen
    });
  }

  logInUser = (response) => {
    localStorage.setItem('Authorization-Token', response.auth_token);
    window.location.reload();
  }

  setGlobalCurrentUser = (user) => {
    global.currentUser = user;
  }

  render () {
    const { classes } = this.props;
    const { mobileOpen, isLoading, loggedIn, showError, alertMessage, alertLevel } = this.state;
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            {isLoading ?
              <LinearProgress color="primary" />
            :
              <React.Fragment>
                <Routes
                  classes={classes}
                  mobileOpen={mobileOpen}
                  loggedIn={loggedIn}
                  logInUser={this.logInUser}
                  alertUser={this.alertUser}
                />
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={showError}
                  autoHideDuration={2500}
                  onClose={this.closeAlert}
                >
                  <MySnackbarContentWrapper
                    onClose={this.closeAlert}
                    variant={alertLevel}
                    message={alertMessage}
                  />
                </Snackbar>
              </React.Fragment>
            }
          </div>
        </ThemeProvider>
      </StripeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
