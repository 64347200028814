import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import client from 'client';
import SignupForm from 'components/SignUp/form';
import VerifyForm from 'components/SignUp/verify';
import css from './index.module.scss';

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `4vw`,
    height: '100%',
  },
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      user: null,
      signupErrors: [],
    };
  }

  componentDidMount() {
    window.analytics.page('/sign-up');
  }

  createUser = (data) => {
    const { alertUser } = this.props;
    client.createUser(data).then((response) => {
      global.currentUser = response;
      window.analytics.identify(response.id);
      this.setState({
        step: 2,
        user: response
      });
    }).catch((error) => {
      const response = error.response.data;
      const signUpErrors = response.errors.map(msg => msg.message).join(', ');
      alertUser(signUpErrors, "error");
    });
  }

  onVerify = (response) => {
    localStorage.setItem('Authorization-Token', response.token);
    window.location.replace('/');
  }

  goToLogin = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    const { alertUser } = this.props;
    const { signupErrors, step, user } = this.state;

    return (
      <Grid className={css.content} container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <div className={css.formContent}>
            <Grid container direction="column" alignItems="center" justify="center">
              <Grid item xs={12}>
                {step === 1 ? (
                  <React.Fragment>
                    <SignupForm errors={signupErrors} handleSubmit={this.createUser} buttonMessage="Verify Phone Number" />
                    <Typography align="center" variant="subtitle2">Already have an account?</Typography>
                    <Grid container direction="row" justify="center" alignItems="center">
                      <Grid item>
                        <Button onClick={this.goToLogin} size="large" color="secondary">
                          <Typography onClick={this.goToLogin} variant="subtitle2" align="center">Log In</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography align="center" color="inherit" variant="h5">Enter Your Verification Code</Typography>
                    {user &&
                      <VerifyForm alertUser={alertUser} user={user} handleSubmit={this.onVerify} />
                    }
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const {
  objectOf,
  object,
} = PropTypes;

SignUp.propTypes = {
  classes: objectOf(object).isRequired,
};

export default withStyles(styles)(SignUp);
