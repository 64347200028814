import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Header from 'components/Header';
import Auth from 'components/Auth/index';
import Account from 'components/Account/index';
import Dashboard from 'components/Enrollment/dashboard';
import SignUp from 'components/SignUp/index';
import Checkout from 'components/Checkout/index';
import Tag from 'components/QrCodes/tag';
import NoMatch from 'components/NoMatch/index';
import { createBrowserHistory } from "history";
const customHistory = createBrowserHistory();

class Routes extends Component {
  state = {
    contentValue: Dashboard,
    tagShow: false
  };

  componentDidMount() {
    const { location } = window;
    const { pathname } = location;
    const match = pathname.match(/\/tags\/.*/);
    if (match && match.length > 0) {
      this.setState({
        tagShow: true
      });
    }
  }

  render() {
    const { classes, loggedIn, logInUser, alertUser } = this.props;
    const { tagShow } = this.state;

    return (
      <React.Fragment>
        {tagShow ? (
          <Router history={customHistory}>
            <Route path="/tags/:secret" render={(props) => <Tag {...props} />} />
          </Router>
        ) : (
          <Router history={customHistory}>
            {loggedIn ? (
              <React.Fragment>
                <div className={classes.app}>
                  <Header history={customHistory} changeContent={this.changeContent} loggedIn={true} />
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={(props) => <Dashboard alertUser={alertUser} history={customHistory} {...props} />}
                      />
                      <Route
                        path="/orders/success"
                        render={(props) => <Dashboard alertUser={alertUser} history={customHistory} {...props} />}
                      />
                      <Route
                        exact
                        path="/orders/cancelled"
                        render={(props) => <Dashboard alertUser={alertUser} history={customHistory} {...props} />}
                      />
                      <Route
                        exact
                        path="/account"
                        render={(props) => <Account alertUser={alertUser} {...props} />}
                      />
                      <Route
                        exact
                        path="/checkout"
                        render={(props) => <Checkout alertUser={alertUser} />}
                      />
                      <Route
                        render={(props) => <Dashboard alertUser={alertUser} {...props} />}
                      />
                    </Switch>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={classes.app}>
                  <Header history={customHistory} changeContent={this.changeContent} loggedIn={false} />
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={(props) => <Auth {...props} alertUser={alertUser} logInUser={logInUser} />}
                    />
                    <Route
                      exact
                      path="/sign-up"
                      render={(props) => <SignUp {...props} alertUser={alertUser} history={customHistory} />}
                    />
                    <Route
                      render={(props) => <NoMatch loggedIn={false} alertUser={alertUser} history={customHistory} />}
                    />
                  </Switch>
                </div>
              </React.Fragment>
            )}
          </Router>
        )}
      </React.Fragment>
    );
  }
}

export default Routes;
