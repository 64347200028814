import React, { Component } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import client from 'client';
import VerifyForm from 'components/SignUp/verify';
import css from './index.module.scss';

class Auth extends Component {
  state = {
    step: 1,
    phone_number: '',
    user: null,
  };

  componentDidMount() {
    window.analytics.page('/sign-in');
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangePhoneNumber = (val) => {
    this.setState({
      phone_number: val
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { alertUser } = this.props;
    const { phone_number } = this.state;
    client.authenticate({
      phone_number: phone_number,
    }).then((resp) => {
      window.analytics.identify(resp.user.id);
      this.setState({
        step: 2,
        user: resp.user,
      });
    }).catch((err) => {
      const response = err.response.data;
      const loginErrors = response.errors.map(msg => msg.message);
      alertUser(loginErrors, "error");
    });
  }

  logInUser = (response) => {
    localStorage.setItem('Authorization-Token', response.token);
    global.currentUser = response.user;

    window.location.replace('/');
  }

  goToSignup = () => {
    const { history } = this.props;
    history.push('/sign-up')
  }

  render() {
    const { user, phone_number, step } = this.state;
    return (
      <Grid className={css.content} justify="center" alignItems="center" container direction="row">
        <Grid item xs={12} md={6}>
          <div className={css.formContent}>
            <Grid container direction="column" alignItems="center" justify="center">
              <Grid item xs={12}>
                <Typography variant="h4" align="left">
                  Log In
                </Typography>
                <Grid item xs={12}>
                  {step !== 1 && (
                    <Typography variant="subtitle1" align="center">
                      We&apos;ve sent you a verification code. Enter it below.
                    </Typography>
                  )}
                </Grid>
                {step === 1 ? (
                  <form onSubmit={this.handleSubmit}>
                    <Grid justify="center" alignItems="center" container direction="row">
                      <Grid item xs={12}>
                        <Grid container direction="row">
                          <Grid item xs={12} className={css.textField}>
                            <MuiPhoneNumber
                              defaultCountry={'us'}
                              onlyCountries={['us']}
                              onChange={this.handleChangePhoneNumber}
                              margin="normal"
                              label="Mobile Phone Number"
                              name="Mobile Phone Number"
                              variant="outlined"
                              required
                              fullWidth
                              className={css.textField}
                              value={phone_number}
                            />
                          </Grid>
                          <Grid container direction="row" justify="center">
                            <Grid item xs={12} className={css.textField}>
                              <Button
                                size="large"
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                Sign In
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <VerifyForm user={user} handleSubmit={this.logInUser} />
                )}
                <Grid container direction="row" justify="center">
                  <Grid item xs={12} className={css.textField}>
                    <Typography variant="subtitle1" align="center">
                      Don&apos;t have an account?
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Button onClick={this.goToSignup} size="large" color="primary" fullWidth>
                      <Typography variant="subtitle2" align="center">Sign Up</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default Auth;
