import React, { Component } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PhoneNumberUpdate from './phoneNumberUpdate';

const styles = theme => ({
  main: {
    flex: 1,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    background: '#eaeff1',
  },
  paper: {
    margin: 'auto',
    padding: theme.spacing(3),
  }
})

class Account extends Component {
  state = {
    isLoading: true,
    billingSignup: false,
    completed: 0,
    snackMessage: '',
  };

  componentDidMount() {
    window.analytics.page('/account');
  }

  alertUser = (message, messageType) => {
    alert(message);
  }

  handleCloseSnack = () => {
    this.setState({
      showSnack: false
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <Grid container direction="row" justify="center" alignItems="center" style={{ height: '50%'}}>
            <Grid item xs={12} md={5}>
              <Paper className={classes.paper} >
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography align="left" variant="h5">Your Account</Typography>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <Typography align="left" variant="subtitle1">Use the form below to update your phone number</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneNumberUpdate alertUser={this.alertUser}/>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Account);
