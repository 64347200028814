import React from 'react';
import { Button, FormControl, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import client from 'client';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  textField: {
    marginTop: 20,
    marginBottom: 20,
  },
  notReceived: {
    marginTop: 20
  }
});

class VerifyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verification_code: '',
      errors: [],
    };
  }

  handleChangeVerificationCode = (e) => {
    this.setState({
      verification_code: e.target.value
    });
  }

  onSubmit = (e) => {
    const { alertUser } = this.props;
    e.preventDefault();
    const { handleSubmit, user } = this.props;
    const { verification_code } = this.state;
    client.verifyPhoneNumber(user, verification_code).then((response) => {
      handleSubmit(response);
    }).catch((error) => {
      const response = error.response.data;
      const verifyErrors = response.errors.map(msg => msg.message);
      alertUser(verifyErrors, "error");
    });
  }

  render() {
    const { classes } = this.props;
    const { verification_code } = this.state;
    return (
      <form onSubmit={this.onSubmit} className={classes.form}>
        <TextField
          size="large"
          variant="outlined"
          color="primary"
          fullWidth
          name="verification_code"
          onChange={this.handleChangeVerificationCode}
          inputProps={{
            minLength: 5
          }}
          label="Verification Code"
          autoFocus
          className={classes.textField}
        />
        <FormControl margin="normal" fullWidth>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={!(verification_code.length === 5)}
            fullWidth
            className={classes.submit}
          >
            Verify
          </Button>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(VerifyForm);
