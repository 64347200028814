import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class Confirmation extends Component {
  render() {
    return (
      <Grid container direction="row">
        <h1>Confirmed</h1>
      </Grid>
    )
  }
}

export default withStyles(styles)(Confirmation);
