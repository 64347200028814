import React, { Component } from 'react';
import { MenuItem, Button, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import client from 'client';

const years = [
  '2020', '2019', '2018', '2017', '2016', '2015',
  '2014', '2013', '2012', '2011', '2010', '2009',
  '2008', '2007', '2006', '2005', '2004', '2003',
  '2002', '2001', '2000', '1999', '1998', '1997',
  '1996', '1995', '1994', '1993', '1992', '1991',
  '1990', '1989', '1988', '1987', '1986', '1985',
  '1984', '1983', '1982', '1981', '1980', '1979',
  '1978', '1977', '1976', '1975', '1974', '1973',
  '1972', '1971', '1970', '1969', '1968', '1967',
  '1966', '1965', '1964', '1963', '1962', '1961',
  '1960', '1959', '1958', '1957', '1956', '1955',
  '1954', '1953', '1952', '1951', '1950'
];

class AddNewDialog extends Component {
  state = {
    year: '',
    make: '',
    model: '',
    license_plate_number: '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  }

  buttonDisabled = () => {
    const { year, make, model } = this.state;

    return !(year.length >= 4 && make.length > 1 && model.length > 1);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { handleClose } = this.props;
    const {
      year,
      make,
      model,
      license_plate_number
    } = this.state;
    const data = {
      year,
      make,
      model,
      license_plate_number
    };

    client.createUserCode(global.currentUser, data).then((res) => {
      handleClose();
    }).catch((err) => {
      alert(err.message);
    })
  }

  render() {
    const { open, handleClose } = this.props;
    const { year, make, model, license_plate_number } = this.state;
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add Another Car
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Use the form below to add another car.
          </DialogContentText>
          <Grid container direction="row">
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                select
                margin="normal"
                label="Year"
                placeholder="2020"
                name="year"
                variant="outlined"
                value={year}
                onChange={this.handleChange('year')}
              >
                {years.map(year => (<MenuItem value={year}>{year}</MenuItem>))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                margin="normal"
                variant="outlined"
                label="Make"
                placeholder="Subaru"
                name="make"
                value={make}
                onChange={this.handleChange('make')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                margin="normal"
                variant="outlined"
                label="Model"
                placeholder="Outback"
                name="model"
                value={model}
                onChange={this.handleChange('model')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="License Plate Number"
                placeholder="444XXYY"
                name="license_plate_number"
                value={license_plate_number}
                onChange={this.handleChange('license_plate_number')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary" variant="contained">
            Add New Car
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddNewDialog;
