import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormControl, Grid, Typography
} from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: 5,
  },
  textField: {
    marginTop: 20,
  },
});

class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: ''
    };
  }

  handleChangePhoneNumber = (val) => {
    this.setState({ phone_number: val });
  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value, passwordHelperText: '' }, this.passwordHelperText);
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { handleSubmit } = this.props;
    const { phone_number } = this.state;
    const user = {
      phone_number
    };

    handleSubmit(user);
  }

  render() {
    const { classes, errors } = this.props;
    const {
      phone_number
    } = this.state;
    const { buttonMessage } = this.props || 'Verify';
    return (
      <form onSubmit={this.onSubmit} className={classes.form}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography align="left" color="inherit" variant="h4">Sign Up</Typography>
              <MuiPhoneNumber
                defaultCountry={'us'}
                onlyCountries={['us']}
                onChange={this.handleChangePhoneNumber}
                margin="normal"
                name="Phone Number"
                variant="outlined"
                label="Mobile Phone Number"
                active={true}
                required
                fullWidth
                className={classes.textField}
                value={phone_number}
              />
              <Typography variant="caption" align="left">We&apos;ll send you a verification code.</Typography>
              {errors.length > 0
                && (
                <div className="errors">
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  {errors.map((error, idx) => (<Typography align="center" key={idx} variant="body2">{error}</Typography>))
                  }
                </div>
                )
              }
              <FormControl margin="normal" fullWidth>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={phone_number.trim().length <= 8}
                  className={classes.submit}
                  fullWidth
                >
                  {buttonMessage}
                </Button>
              </FormControl>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const {
  objectOf, object, func,
} = PropTypes;

SignupForm.propTypes = {
  handleSubmit: func.isRequired,
  classes: objectOf(object).isRequired,
};

export default withStyles(styles)(SignupForm);
