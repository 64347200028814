import React, { Component } from 'react';
import { Button, Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { loadStripe } from '@stripe/stripe-js';
import { withStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import ReactSVG from 'react-svg';
import client from 'client';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const QRCode = require('qrcode.react');
const logo = require('logo.svg');

const styles = theme => ({
  marginTop: {
    marginTop: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    paddingBottom: theme.spacing(5),
  },
  padded: {
    padding: theme.spacing(1)
  },
  tagButton: {
    height: theme.spacing(8),
    fontSize: '1.338rem'
  },
  canvas: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  },
  palette: theme,
  // tagMaxWidth: {
  //   maxWidth: 560
  // }
});

const PurpleButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);

const DEFAULT_WIDTH = 800;

const GreenButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button);

class TagShow extends Component {
  state = {
    contactedUser: false
  }

  componentDidMount() {
    if (document.getElementById('qrCodeContainer')) {
      this.width = document.getElementById('qrCodeContainer').offsetWidth;
    }
  }

  contactPerson = name => () => {
    const { actionable, tag } = this.props;
    if (actionable) {
      client.contactCustomer(tag, name).then((res) => {
        this.setState({
          contactedUser: true
        });
      }).catch((err) => {
        alert(err.message);
      })
    }
  }

  handleClickBuy = async (event) => {
    const { alertUser } = this.props;
    client.createCheckoutSession().then( async (res) => {
      const checkoutSessionId = res.id;
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSessionId
      });
      alertUser(error.message, "error");
    });
  };

  render() {
    const { classes, tag, showBuy } = this.props;
    const { contactedUser } = this.state;

    let width = this.width;
    if (width === undefined) {
      width = DEFAULT_WIDTH;
    }

    return (
      <Grid container direction="row" justify="center" alignItems="center">
        {!contactedUser ? (
          <React.Fragment>
            {showBuy && (
              <Grid container direction="row" justify="center" spacing={1}>
                <Grid item xs={11} sm={11} md={7} lg={7}>
                  <Grid className={classes.marginBottom} container direction="row" spacing={1} alignItems="flex-start">
                    {global.currentUser && global.currentUser.subscription_id == null && (
                      <Grid item xs={12} md={12}>
                        <Button onClick={this.handleClickBuy} id="buyDecal" variant="contained" color="secondary" fullWidth size="large"><ShoppingCart />Start Subscription</Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container direction="row" justify="center" spacing={1}>
              <Grid item sm={11} xs={11} md={7} lg={7}>
                <Paper id="tagShow" className={classes.paper}>
                  <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
                    <Grid item xs={12}>
                      <Toolbar className={classes.padded} disableGutters>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs>
                            <ReactSVG
                              src={logo}
                              beforeInjection={svg => {
                                svg.setAttribute('style', 'width: 48px; height: 48px;')
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Toolbar>
                    </Grid>
                    <Grid item id="qrCodeContainer" xs={12}>
                      {tag &&
                        <QRCode className={classes.canvas} size={width / 4} value={`https://${window.location.host}/tags/${tag.secret}`} />
                      }
                    </Grid>
                    <Grid item xs={11} sm={10}>
                      <Typography variant="h6" align="center">To contact the car owner, scan the code above using the camera on your phone and choose one of the options below when it appears on your screen.</Typography>
                    </Grid>
                    <Grid item />
                    <Grid item xs={12} justify="center" alignItems="center" container direction="row" spacing={3}>
                      <Grid item xs={12} sm={9}>
                        <Button elevation={0} onClick={this.contactPerson('move')} className={classes.tagButton} variant="contained" color="primary" fullWidth>Please Move Your Car</Button>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Button elevation={0} onClick={this.contactPerson('expired')} className={classes.tagButton} variant="contained" color="secondary" fullWidth>Your Parking Expired</Button>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <PurpleButton elevation={0} onClick={this.contactPerson('broken_into')} className={classes.tagButton} variant="contained" fullWidth>Your car was broken into</PurpleButton>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <GreenButton elevation={0} onClick={this.contactPerson('accident')} className={classes.tagButton} variant="contained"  fullWidth>Your car was hit</GreenButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item xs={8}>
            <Grid container direction="row" spacing={3} justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h3" align="center">
                  Thank you!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  We&apos;ve contacted the owner of this car.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(TagShow);
